import React from "react";
import { Rotate, Slide } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";

export default function OurProduct() {
    return (
        <>
            <section className="our_product py-5">
                <Container>
                    <Slide>
                        <h2 className="text-center">Our Product</h2>
                    </Slide>
                    <Row className="mt-4">
                        <Col md={4} className="d-flex justify-content-center">
                            <Rotate className="w-100">
                                <div className="d-flex">
                                    <div className="coin position-relative w-25">
                                        <div class="triangle-down"></div>
                                        <img src="images/coin.jpg.png" alt="" className="img-fluid" width={50} />
                                    </div>
                                    <div className="coin_txt position-relative w-75">
                                        <div>
                                            <div>OMEGA BLOCKCHAIN</div>
                                            <p className="mb-0">Crypto Exchange Platform</p>
                                            <div class="triangle-down2"></div>
                                        </div>
                                    </div>
                                </div>
                            </Rotate>
                        </Col>
                        <Col md={4} className="d-flex justify-content-center mt-3 mt-md-0">
                            <Rotate className="w-100">
                                <div className="d-flex">
                                    <div className="coin position-relative w-25">
                                        <div class="triangle-down"></div>
                                        <img src="images/coin.jpg.png" alt="" className="img-fluid" width={50} />
                                    </div>
                                    <div className="coin_txt position-relative w-75">
                                        <div>
                                            <div>OMEGA BLOCKCHAIN</div>
                                            <p className="mb-0">Crypto Exchange Platform</p>
                                            <div class="triangle-down2"></div>
                                        </div>
                                    </div>
                                </div>
                            </Rotate>
                        </Col>
                        <Col md={4} className="d-flex justify-content-center mt-3 mt-md-0">
                            <Rotate className="w-100">
                                <div className="d-flex">
                                    <div className="coin position-relative w-25">
                                        <div class="triangle-down"></div>
                                        <img src="images/coin.jpg.png" alt="" className="img-fluid" width={50} />
                                    </div>
                                    <div className="coin_txt position-relative w-75">
                                        <div>
                                            <div>OMEGA BLOCKCHAIN</div>
                                            <p className="mb-0">Crypto Exchange Platform</p>
                                            <div class="triangle-down2"></div>
                                        </div>
                                    </div>
                                </div>
                            </Rotate>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}