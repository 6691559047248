import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';

export default function Faq() {
    return (
        <>
            <section className="fsq py-5">
                <Container>
                    <h2 className="text-center my-2">Frequently Asked Questions</h2>

                    <Row className="mt-4">
                        <Col md={6}>
                            <div>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>What is a OMEGA BLOCKCHAIN?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Why choose OMEGA BLOCKCHAIN solution?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>What are the key features of OMEGA BLOCKCHAIN?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>How does OMEGA BLOCKCHAIN ensure security on its   blockchain?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Can I integrate existing systems with OMEGA BLOCKCHAIN?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>What industries can benefit from OMEGA BLOCKCHAIN solution?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>How does consensus work on OMEGA BLOCKCHAIN?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>How can I get started with OMEGA BLOCKCHAIN solution?</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet,
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}