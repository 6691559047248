import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function Ourfeatures() {
    return (
        <>
            <section className="py-5 our_features">
                <Container>
                    <h2>Our features</h2>

                    <Row>
                        <Col lg={4}>
                            <div className="box">
                                <h4 className="mb-3">Blockchain as a service</h4>

                                <p className="mb-4">The future of cloud is servless: for enterprice, government and Web3 application- Build with Cloud 3.0.</p>

                                <Button variant="default" className="btn_uni p-0">
                                    <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                    <div>Learn About Cloud 3.0</div>
                                </Button>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="box">
                                <h4 className="mb-3">Open Internet Services</h4>

                                <p className="mb-4">Now internet communication take full and exclusive control and ownership of entire internet services - Create your first DAO on true Web 3.0.</p>

                                <Button variant="default" className="btn_uni p-0">
                                    <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                    <div>Explore Dao On ICP</div>
                                </Button>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="box">
                                <h4 className="mb-3">Your Web3 ID</h4>

                                <p className="mb-4">Web2 relies on usernames and passwords for authentication - Internet Identify is the most service authentication for web2 and web3.</p>

                                <Button variant="default" className="btn_uni p-0">
                                    <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                    <div>One ID For All Service</div>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}