import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { IoMdCall } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";

export default function ContactUs() {
    return (
        <>
            <section className="contact_us py-5">
                <Container>
                    <div>
                        <h2>Contact Us</h2>
                    </div>

                    <div className="cu_box mt-4">
                        <Row>
                            <Col md={5}>
                                <div>
                                    <h3>Get in Touch</h3>
                                    <p>Want to get in touch? We'd love to hear from you.</p>
                                </div>

                                <div>
                                    <img src="images/contact.png" className="img-fluid" alt="" width={350} />
                                </div>

                                <div className="get_touch mt-3">
                                    <span className="d-flex me-1"><IoMdCall /></span>
                                    <span>+91-999999999</span>
                                </div>

                                <div className="get_touch mt-2">
                                    <span className="d-flex me-1"><MdOutlineEmail /></span>
                                    <span>info@omgblockchain.com</span>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="">
                                    <Form>

                                        <div className="my-4">
                                            <Form.Control
                                                type="text"
                                                placeholder="Your Name"
                                            />
                                        </div>

                                        <div className="my-4">
                                            <Form.Control
                                                type="email"
                                                placeholder="Email address"
                                            />
                                        </div>

                                        <div className="my-4">
                                            <Form.Control
                                                type="text"
                                                placeholder="Subject"
                                            />
                                        </div>

                                        <div className="my-4">
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Message"
                                                rows={3}
                                            />
                                        </div>

                                        <div className="mt-2 text-center">
                                            <Button variant="default" className="btn_uni p-0">
                                                <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                                <div>Explore Dao On ICP</div>
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}