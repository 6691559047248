import React from "react";
import { Roll, Zoom } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";

export default function AboutBanner() {
    return (
        <>
            <section className="about_banner py-5">
                <Container>
                    <div className="a_b">
                        <Row>
                            <Col lg={4}>

                                <div className="text-center text-lg-end me-2">
                                    <Roll>
                                        <span className="position-relative">
                                            <img src="images/coin.jpg.png" alt="" className="img-fluid ms-2" width={300} />
                                            <div className="roket">
                                                <img src="images/roket.png" alt="" className="img-fluid" width={180} />
                                            </div>
                                        </span>
                                    </Roll>
                                </div>
                            </Col>

                            <Col lg={8} className="mt-5 mt-md-0">

                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <Zoom>
                                        <div>
                                            <h2>About <small>OMEGA BLOCKCHAIN</small></h2>
                                            <p className="mb-1">Welcome to OMEGA BLOCKCHAIN, where we're revolutionizing the way businesses interact and transact securely with our cutting-edge private blockchain technology.</p>

                                            <p className="mb-1">At  Blockchain, we understand the critical importance of trust, security, and efficiency in today's digital landscape. That's why we've developed a robust private blockchain platform tailored to meet the specific needs of businesses across various industries.</p>

                                            <p className="mb-1">Our team of Blockchain experts brings together a wealth of experience and expertise to create custom solutions that empower organizations to streamline processes, reduce costs, and mitigate risks associated with traditional centralized systems.</p>

                                            <p className="mb-1">With our blockchain technology, businesses can enjoy:</p>
                                        </div>
                                    </Zoom>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}