
import './App.css';
import ComingSoon from './component/ComingSoon/ComingSoon.jsx';
import AboutBanner from './Home/AboutBanner.jsx';
import Banner from './Home/Banner.jsx';
import ContactUs from './Home/ContactUs.jsx';
import Faq from './Home/Faq.jsx';
import OurProduct from './Home/OurProduct.jsx';
import Ourfeatures from './Home/Ourfeatures.jsx';
import UnlockPower from './Home/UnlockPower.jsx';
import YourBusiness from './Home/YourBusiness.jsx';
import Footer from './component/Layout/Footer.jsx';
import Header from './component/Layout/Header.jsx';

function App() {
  return (
    <>
      {/* <ComingSoon /> */}
      <Header />
      <Banner />
      <AboutBanner />
      <OurProduct />
      <Ourfeatures />
      <UnlockPower />
      <YourBusiness />
      <Faq />
      <ContactUs />
      <Footer/>
    </>
  );
}

export default App;
