import React from "react";
import { Slide, Zoom } from "react-awesome-reveal";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function Banner() {
    return (
        <>
            <section className="banner">
                <Container className="">
                    <Row className="align-items-center">
                        <Col md={6}>
                            <Zoom>
                                <div className="h-100 d-flex justify-content-end">
                                    <div className="bnner_tx">
                                        <div className="head">We're Coming Soon!</div>
                                        <h1>Blockchain</h1>
                                        <p>At OMEGA BLOCKCHAIN, we are committed to revolutionizing [industry/domain] through the power of blockchain technology. Our  blockchain network is designed to provide secure, transparent, and efficient solutions tailored to the unique needs of our stakeholders.</p>

                                        <div className="mt-2">
                                            <Button variant="default" className="btn_uni p-0">
                                                <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                                <div>Explore More</div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Zoom>
                        </Col>
                        <Col md={6} className="text-center">
                            <div className="bnner_img">
                                {/* <div className="text-start ms-0 ms-md-4">
                                    <img src="images/Phone.png" alt="" className="ms-0 ms-md-5 img-fluid " width={300} />
                                </div> */}
                                <div>
                                    <img src="images/banner_hnd.png" alt="" className="img-fluid" width={600} />
                                </div>
                            </div>
                        </Col>

                        <Col md={12} className="mt-5">
                            <Slide>
                                <Row>
                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/1.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/2.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/3.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/4.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/5.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/6.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/7.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/8.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>

                                    <Col>
                                        <div className="banner_logos">
                                            <img src="images/logos/9.png" alt="" className="img-fluid" />
                                        </div>
                                    </Col>
                                </Row>
                            </Slide>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}