import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
    return (
        <>
            <section className="footer py-5">
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                <img src="images/coin.jpg.png" alt="" className="img-fluid me-2" width={50} />
                                <div className="logo_text fs-5"><span>OMEGA</span>BLOCKCHAIN</div>
                            </div>
                        </Col>
                        <Col lg={7} className="mt-3">
                            <div className="footer_links h-100 text-end">
                                <div className="d-inline-block me-4">
                                    <a href="">About Us</a>
                                </div>
                                <div className="d-inline-block me-4">
                                    <a href="">FAQ's</a>
                                </div>
                                {/* <div>
                                    <a href="">Roadmap</a>
                                </div>
                                <div>
                                    <a href="">Whitepaper</a>
                                </div> */}
                                <div className="d-inline-block me-4">
                                    <a href="">Privacy Policy</a>
                                </div>
                                <div className="d-inline-block">
                                    <a href="">Cookie Policy</a>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <hr />

                    <div className="text-center text-md-start d-block d-md-flex justify-content-between align-items-center">
                        <p>© 2024 OMEGA BLOCKCHAIN. All rights reserved.</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <span className="ftr_icn"><FaInstagram /></span>
                            <span className="ftr_icn"><FaFacebook /></span>
                            <span className="ftr_icn"><FaYoutube /></span>
                            <span className="ftr_icn"><FaLinkedin /></span>
                            <span className="ftr_icn"><FaXTwitter /></span>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}