import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function UnlockPower() {
    return (
        <>
            <section className="unlock_power py-5">
                <Container>
                    <div className="up_box">
                        <Row>
                            <Col md={7}>
                                <h2>Unlock the Power of Web3 Today!</h2>
                                <p>Join us on the journey to the decentralized future.</p>
                            </Col>

                            <Col md={5} className="text-md-end">
                                <Button variant="default" className="btn_uni p-0">
                                    <img src="btn_bg.png" alt="" className="img-fluid" width={225} />
                                    <div>Explore Dao On ICP</div>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}