import React from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Header() {
    return (
        <>
            <section className="header">
                <Container>
                    <Navbar expand="lg" className="bg-body-tertiary">
                        <Container fluid>
                            <Navbar.Brand href="#">
                                <div className="logo">
                                    <img src="images/coin.jpg.png" alt="" className="img-fluid me-2" width={100} />
                                    <span className="logo_text"><span>OMEGA</span> BLOCKCHAIN</span>
                                </div>
                            </Navbar.Brand>
                            <div>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                            </div>
                            <Navbar.Collapse id="navbarScroll" className="order-2 order-lg-1">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                    <Nav.Link href="#action1">About</Nav.Link>
                                    <Nav.Link href="#action2">Product</Nav.Link>
                                    <Nav.Link href="#action3">Features</Nav.Link>
                                    <Nav.Link href="#4">FAQ</Nav.Link>
                                    <Nav.Link href="#5">Contact</Nav.Link>
                                </Nav>


                            </Navbar.Collapse>

                            <Button variant="default" className="btn_uni order-1 order-lg-2">
                                <img src="btn_bg.png" alt="" className="img-fluid" width={150} />
                                <div>Connect Wallet</div>
                            </Button>
                        </Container>
                    </Navbar>
                </Container>
            </section>
        </>
    )
}