import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

export default function YourBusiness() {
    return (
        <>
            <section className="your_business py-5">
                <Container>
                    <div className="yb_box">
                        <Row>
                            <Col md={6}>
                                <div className="">
                                    <img src="images/CITYPNG.png" className="img-fluid" alt="" />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className="d-flex align-items-center h-100">
                                    <div>
                                        <h2>Your <span style={{ color: '#15af80' }}>Business</span> Ready For The Blockchain</h2>
                                        <p>Empower your business with the transformative potential of blockchain technology! Our cutting-edge solutions offer unparalleled security, transparency, and efficiency, revolutionizing traditional processes.</p>

                                        <div className="d-flex">
                                            <Form.Control
                                                type="email"
                                                placeholder="Your Email address ..."
                                                className="w-50"
                                            />

                                            <Button variant="default" className="btn_uni order-1 order-lg-2">
                                                <img src="btn_bg.png" alt="" className="img-fluid" width={150} />
                                                <div>Connect Wallet</div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}